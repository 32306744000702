<template>
  <div id="information">
    <div class="top"></div>
    <div class="news wrapper">
      <div class="title">
        <router-link to="/dash">首页<span v-html="'&nbsp'"></span></router-link>
        <router-link to="/help"
          >-<span v-html="'&nbsp'"></span>帮助<span v-html="'&nbsp'"></span
        ></router-link>
        <a href="javascript:void(0)"
          >-<span v-html="'&nbsp'"></span>自建房的房屋租赁税计算</a
        >
      </div>
      <div class="content">
        <el-row>
          <el-col :span="18" class="left">
            <div class="news_title">
              <div class="newsText">自建房的房屋租赁税计算</div>
            </div>
            <div class="news_content">
              <p>
                房屋租赁管理中的自建房的房屋租赁税计算，什么是房屋租赁税呢?有很多地区的居民们都是在自家土地上盖房子，除了自家居住外，可运用房屋出租软件将剩余的房间管理出租，过上收租生活，当然这也是需要缴纳一定的房屋租赁税的，自建房的房屋租赁税的计算是怎样的呢?
              </p>
              <br /><br /><br /><br />
              <p>核定自建房租金收入的计算公式：</p>
              <p>1.核定租金收入=住宅租金收入+商业租金收入</p>
              <p>2.住宅租金收入=可出租住宅面积×住宅指导租金×住宅出租率</p>
              <p>3.商业租金收入=商铺面积×商业指导租金×商铺出租率</p>
              <p>
                4.可出租住宅面积=(整栋房屋层数—业主自用层数)×每层平均建筑面积
              </p>
              <p>5.商铺面积=商铺租赁双方合同约定实际面积</p>
              <br /><br /><br /><br />
              <p>
                居民自建房建筑面积是征收税费的计算依据。居民自建房税费征收以栋为单位计算总建筑面积。如房地产权利证书或者有效材料表明同一栋楼房的合法所有权或使用权分属两位或两位以上业主的，可对业主分别计算总建筑面积。
              </p>
              <br />
              <p>
                住宅指导租金和商业指导租金为市租赁管理办每年度公布的房屋租赁指导租金，特殊情况下也可采用租赁管理所上报区、市租赁管理办批准的指导租金。出租屋管理系统中住宅出租率和商铺出租率为该房屋所属区域的已出租房屋面积占可出租房屋面积的比例。
              </p>
              <br />
              <p>应缴房屋租赁管理费=核定租金收入×租赁管理费率</p>
            </div>
          </el-col>
          <el-col :span="5" class="right">
            <div class="recommendTitle">推荐</div>
            <div class="recommendList">
              <div class="recommendItem">
                <router-link to="/news1">
                  <img
                    src="../../assets/images/information/news1.jpeg"
                    alt=""
                  />
                  <div class="msg">
                    住房租赁市场回暖 监管强化助力住房租赁市场规范发展
                  </div>
                </router-link>
              </div>
              <div class="recommendItem">
                <router-link to="/news2">
                  <img
                    src="../../assets/images/information/news2.jpeg"
                    alt=""
                  />
                  <div class="msg">
                    六省市整治住房租赁市场 住房租赁立法亟须按下“快进键”
                  </div>
                </router-link>
              </div>
              <div class="recommendItem">
                <router-link to="/news3">
                  <img src="../../assets/images/information/new3.jpeg" alt="" />
                  <div class="msg">房屋租赁经纪行业制度</div>
                </router-link>
              </div>
              <div class="recommendItem">
                <router-link to="/news4">
                  <img src="../../assets/images/information/new4.jpeg" alt="" />
                  <div class="msg">中央加快住房租赁市场立法</div>
                </router-link>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
#information {
  .top {
    position: relative;
    top: 0;
    left: 0;
    height: 460px;
    width: 100%;
    background-image: url(../../assets/images/help/帮助背景.png);
    background-size: cover;
  }
  .news {
    .title {
      padding: 12px 0;
      margin-top: 20px;
      text-align: left;
      color: rgba(255, 255, 255, 0.7);
      border-bottom: 1px solid #d2d2d2;
      a {
        color: #5a5e66;
      }
    }
    .content {
      padding-bottom: 50px;
      .left {
        margin-top: 25px;
        .news_title {
          color: #000;
          overflow: hidden;
        }
        .newsText {
          height: 55px;
          line-height: 55px;
          text-align: center;
          font-size: 20px;
          background-color: #f1f3f6;
        }
        .news_content {
          text-align: left;
          color: #000;
          .bold {
            font-weight: 700;
            font-size: 18px;
          }
          p {
            font-size: 16px;
            font-weight: 300;
            color: rgb(51, 51, 51);
            text-align: justify;
            line-height: 24px;
          }
        }
      }
      .right {
        margin: 25px 0 0 50px;
        img {
          width: 200px;
          height: 115px;
        }
        background: #f2f6fa;
        min-height: 300px;
        .recommendTitle {
          color: #000;
          text-align: left;
          font-weight: bold;
          padding: 0 10px;
          margin: 20px 0;
          border-left: 3px solid #3b6798;
        }
        .recommendList {
          padding: 0 0 10px;
          .recommendItem {
            padding: 0 10%;
            margin-bottom: 15px;
            .msg {
              color: #717171;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
